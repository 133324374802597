import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { isEqual } from "lodash";
import { eventEmitter } from "../../../framework/src/utils/EventEmitter";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { analytics } from "../../../components/src/Firebase";
import { logEvent } from "firebase/analytics";

interface BrandType1 {
  id: number
  name: string
  created_at: string
  updated_at: string
  currency: string
}

interface SubCategoryType1 {
  id: number,
  name: string
  created_at: string
  updated_at: string
  parent_id: null | string
  rank: null | string
  category_id: number
}

interface CategoryType1 {
  id: number,
  name: string,
  slug: string,
  dark_icon: null | string,
  dark_icon_active: null | string,
  dark_icon_inactive: null | string,
  light_icon: null | string,
  light_icon_active: null | string,
  light_icon_inactive: null | string,
  rank: null | string,
  created_at: string,
  updated_at: string,
  order: number,
  sub_categories: SubCategoryType1[]
  selected_sub_categories: null | string,
  image: null | string
}

interface SelectedSubCategory1 {
  id: number
  name: string
  slug: string
  created_at: string
  updated_at: string
}

interface ImagesType1 {
  id?: number
  url: string
}

interface VariantOption1 {
  id: number
  product_variant_id: null | string
  catalogue_variant_property_id: null | string
  created_at: string
  updated_at: string
  name: string
  account_id: number
}

interface VariantPropAttridute1 {
  id: number
  name: string
  variant_option: VariantOption1
  count: number
}

interface VariantProperty1 {
  id: string,
  type: string,
  attributes: VariantPropAttridute1
}
interface CatalogueAttributes1 {
  id: number
  catalogue_id: number
  tax_amount: number
  taxable_amount: number
  stock_qty: number
  price: string
  discount: string
  discount_price: null | string
  length: null | string
  breadth: null | string
  height: null | string
  default: boolean
  visibility: boolean
  sale_price: string
  created_at: string
  updated_at: string
  images: ImagesType1[]
  catalogue_variant_properties: {
    data: VariantProperty1[]
  }
}
interface CatalogueVariant1 {
  id: string
  type: string
  attributes: CatalogueAttributes1
}

interface ExtraInfo {
  id: number
  title: string
  description: string
  catalogue_id: number
}
interface ProductAttridute1 {
  id: number
  slug: string
  brand: BrandType1
  reviews: string[]
  tax_amount: number
  taxable_amount: number
  name: string
  sku: string
  description: string
  manufacture_date: null | string
  stock_qty: number
  availability: string
  weight: string
  price: number
  recommended: boolean
  sale_price: string
  discount: string
  tax: number
  archive: boolean
  on_sale: boolean
  status: string
  catalogue_id: number
  trending_product: boolean
  variant_id: number
  category: CategoryType1
  selected_sub_category: SelectedSubCategory1
  images: ImagesType1[]
  discounted_price: string
  average_rating: number
  catalogue_variant_product: CatalogueVariant1[]
  catalogue_variants: CatalogueVariant1[]
  extra_infos: ExtraInfo[]
  search_engine_listing: {
    title: string
    description: string
  }
  video_url: string
}
interface ProductData1 {
  id: string
  type: string
  attributes: ProductAttridute1
}

interface OptionsList1 {
  isSelected?: boolean
  label?: string
  parentValue: string
  value: string
  disabled?: boolean
}
interface VariantOptions1 {
  id: string
  name: string
  optionsList: OptionsList1[]
}

interface Catalogue1 {
  archive: boolean
  availability: string
  block_qty: null | number
  brand_id: number
  brand_name: string
  category_id: number
  created_at: string
  description: string
  discount: string
  id: number
  manufacture_date: null | string
  name: string
  on_sale: boolean
  price: number
  recommended: boolean
  sale_price: string
  sku: string
  status: string
  stock_qty: number
  sub_category_id: number
  tax: number
  tax_amount: number
  taxable_amount: number
  updated_at: string
  uploaded_by: null | string
  weight: string
}

interface CatalogueVariant2 {
  block_qty: number
  breadth: null | string
  catalogue_id: number
  catalogue_variant_color_id: number
  catalogue_variant_size_id: number
  created_at: string
  default: boolean
  discount: string
  discount_price: null | string
  height: null | string
  id: number
  images: string[]
  length: null | string
  name: string
  on_sale: string
  price: string
  sale_price: string
  stock_qty: number
  tax_amount: number
  taxable_amount: number
  visibility: boolean
  updated_at: string
  weight: string
}

interface CatalogueVarientProperty2 {
  catalogue_variant_id: number
  created_at: string
  id: number
  name: string
  parent_id: number
  updated_at: string
  value: string
  variant_option_id: number
  variant_option_name: string
}
interface CartItemsAttribute1 {
  cart_id: number
  id: number
  price_sub_total: number
  quantity: number
  sale_price_sub_total: null | string
  catalogue: Catalogue1
  catalogue_image: {
    url: string
  }[]
  catalogue_variant: CatalogueVariant2
  catalogue_variant_properties: CatalogueVarientProperty2[]
}
interface CartItems1 {
  id: string
  type: string
  attributes: CartItemsAttribute1
}

interface CartItemsResponse {
  data: {
    id: string
    type: string
    attributes: {
      cart_items_count: number
      id: number
      promo_code: null | string
      promo_code_id: null | string
      shipping_charge: null | string
      total_cart_amount: null | string
      total_cart_price_after_discount: null | string
      total_with_tax: string
      with_shipping_charge: null | string
      cart_items: CartItems1[]
    }
  };
  error?: string
}

interface AddCartItem1 {
  data: {
    id: string
    type: string
    attributes: CartItemsAttribute1
  }[]
  error?: string
}

interface StoreAttributes1 {
  id: number
  name: string
  email: string
  sender_email: string
  customer_support_email: string
  country_id: number
  address_state_id: number
  city_id: number
  zipcode: string
  address_line_1: string
  address_line_2: string
  phone_number: string
  is_whatsapp_integration: boolean
  whatsapp_number: string
  whatsapp_message: string
  currency_id: number
  gst_number: string
  currency_symbol: string
  country_code: string
  created_at: string
  updated_at: string
  country_name: string
}
interface StoreDetailsType1 {
  data: {
    id: string
    type: string
    attributes: StoreAttributes1
  }

}
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  productData: ProductData1;
  variantOptions: VariantOptions1[];
  screenSize: "sm" | "md" | "lg" | "xl";
  cartId: number;
  currencySymbol: string;
  email: string;
  build_card: string;
  addedToBag: boolean;
  itemExistsInCart: boolean;
  cartItemCount: number;
  loader: boolean;
  AddToBagText: string;
  skeletonLoader: boolean;
  variantData: CatalogueVariant1 | undefined;
  snackBarOpen: boolean;
  videoModalOpen: boolean;
  showOutOfStockMsg: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = "";
  createCartApiCallId: string = "";
  addItemToCartApiCallId: string = "";
  getStoreDetailsProductCallId: string = "";
  getCartItemsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      showOutOfStockMsg: false,
      productData: {
        id: '',
        type: '',
        attributes: {
          id: 0,
          slug: '',
          brand: {
            id: 0,
            name: '',
            created_at: '',
            updated_at: '',
            currency: ''
          },
          reviews: [],
          tax_amount: 0,
          taxable_amount: 0,
          name: '',
          sku: '',
          description: '',
          manufacture_date: null,
          stock_qty: 0,
          availability: '',
          weight: '',
          price: 0,
          recommended: false,
          sale_price: '',
          discount: '',
          tax: 0,
          archive: false,
          on_sale: false,
          status: '',
          catalogue_id: 0,
          trending_product: false,
          variant_id: 0,
          category: {
            id: 0,
            name: '',
            slug: '',
            dark_icon: null,
            dark_icon_active: null,
            dark_icon_inactive: null,
            light_icon: null,
            light_icon_active: null,
            light_icon_inactive: null,
            rank: null,
            created_at: '',
            updated_at: '',
            order: 0,
            sub_categories: [],
            selected_sub_categories: null,
            image: null
          },
          selected_sub_category: {
            id: 0,
            slug: '',
            name: '',
            created_at: '',
            updated_at: ''
          },
          images: [],
          discounted_price: '',
          average_rating: 0,
          catalogue_variant_product: [],
          catalogue_variants: [],
          extra_infos: [],
          search_engine_listing: {
            title: '',
            description: ''
          },
          video_url: ''
        }
      },
      email: '',
      build_card: '',
      screenSize: "sm",
      variantOptions: [],
      cartId: 0,
      currencySymbol: "",
      addedToBag: false,
      itemExistsInCart: false,
      cartItemCount: 0,
      loader: false,
      AddToBagText: 'Add to bag',
      skeletonLoader: true,
      variantData: {
        id: '',
        type: '',
        attributes: {
          id: 0,
          catalogue_id: 0,
          tax_amount: 0,
          taxable_amount: 0,
          stock_qty: 0,
          price: '',
          sale_price: '',
          discount: '',
          discount_price: '',
          length: '',
          breadth: '',
          height: '',
          default: false,
          visibility: false,
          created_at: '',
          updated_at: '',
          images: [],
          catalogue_variant_properties: { data: [] }
        }
      },
      snackBarOpen: false,
      videoModalOpen: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.productApiItemCallId) {
        if (responseJson?.data) {
          if (configJSON.notActiveArr.includes(responseJson.data.attributes.status)) {
            this.setState({ snackBarOpen: true }, () => {
              document.body.style.pointerEvents = "none";
              setTimeout(() => window.location.href = "/catalogue", 6000);
            });
          }
          const catalogueId = responseJson.data.attributes.catalogue_id;
          const variantId = responseJson.data.attributes.variant_id;
          const slug = this.getSlug(responseJson.data);
          this.handleVarRedirect(catalogueId, variantId, slug);
          this.setState({
            productData: responseJson.data,
            variantOptions: this.generateVariantOptions(responseJson.data),
            skeletonLoader: false
          }, this.handleVariantData);
        }
      } else if (apiRequestCallId === this.getStoreDetailsProductCallId) {
        this.handleStoreDetailsProductResponse(responseJson)
      } else if (apiRequestCallId === this.addItemToCartApiCallId) {
        this.handleAddItemToCartResponse(responseJson)
      } else if (apiRequestCallId === this.createCartApiCallId) {
        this.handleCreateCartResponse(responseJson)
      } else if (apiRequestCallId === this.getCartItemsCallId) {
        this.handleCartItemsCallIdResponse(responseJson)
      } else {
        this.parseApiErrorResponse(errorResponse);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleVarRedirect = (catalogueId: string, variantId: string, slug: string) => {
    const variants = this.state.productData?.attributes.catalogue_variants;
    const urlVarId = this.getNavParams().variantId;
    if (catalogueId !== variantId && !urlVarId && !variants.find(variant => variant.id === urlVarId)) {
      this.navigateToVariant(slug, variantId);
    }
  }

  handleCartItemsCallIdResponse = (responseJson: CartItemsResponse) => {
    if (responseJson?.data) {
      const count = responseJson?.data.attributes.cart_items_count
      this.setState({
        cartItemCount: count,
      })
    } else if (responseJson?.error) {
      this.createCartApi();
    }
  }

  handleAddItemToCartResponse = async (responseJson: AddCartItem1) => {
    if (responseJson?.data) {
      const dataLog = {
        value: this.state.productData.attributes.price,
        currency: this.state.currencySymbol,
        items: [{
          item_id: this.state.productData.id,
          price: this.state.productData.attributes.price,
          item_name: this.state.productData.attributes.name,
        }]
      }

      logEvent(analytics, "add_to_cart", dataLog);

      mixpanel.track(configJSON.addToCartSuccessEvent, { email: this.state.email, buildCardId: this.state.build_card });
      this.setState({ addedToBag: true, loader: false }, () => this.setState({ AddToBagText: "Go to bag" }))
      if (!this.state.itemExistsInCart) {
        const count = this.state.cartItemCount + 1;
        this.setState({
          cartItemCount: count
        })
        eventEmitter.dispatch("badgeValue", count);
      }
    } else if (responseJson?.error === "Stock quantity exceeds") {
      this.setState({ loader: false, showOutOfStockMsg: true })
    }
  }

  closeOutOfStockMsg = () => {
    this.setState({ showOutOfStockMsg: false })
  }

  handleCreateCartResponse = (responseJson: CartItemsResponse) => {
    if (responseJson?.data) {
      logEvent(analytics, "cart_creation");
      this.setState({ cartId: responseJson.data.attributes.id })
      setStorageData('cartId', responseJson.data.attributes.id);
      this.addItemToCartApi()
    }
  }

  handleStoreDetailsProductResponse = (responseJson: StoreDetailsType1) => {
    if (responseJson?.data) {
      this.setState({ currencySymbol: responseJson.data.attributes.currency_symbol });
    }
  }

  getStoreDetailsAtCatalogue = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMesag = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStoreDetailsProductCallId = requestMesag.messageId;

    requestMesag.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.storeDetailsEndpoint
    );

    requestMesag.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMesag.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );

    runEngine.sendMessage(requestMesag.id, requestMesag);
  };

  handleResize = () => {
    const screenWidth = document.documentElement.clientWidth ?? 0;
    let currentScreenSize: S["screenSize"] = "xl";

    if (screenWidth <= 480) {
      currentScreenSize = "sm";
    }
    else if (screenWidth <= 768) {
      currentScreenSize = "md";
    }
    else if (screenWidth <= 992) {
      currentScreenSize = "lg"
    }

    if (currentScreenSize !== this.state.screenSize) {
      this.setState({ screenSize: currentScreenSize })
    }
  }

  async componentDidMount() {
    const adminUsre = await getStorageData("eventData")
    if (adminUsre) {
      const adminData = JSON.parse(adminUsre)
      mixpanel.track(configJSON.prodctViewSuccess, { email: adminData.email, buildCardId: adminData.build_card });
      this.setState({ ...this.state, email: adminData.email, build_card: adminData.build_card })

    }
    let cartIdExist = localStorage.getItem('cartId');
    if (cartIdExist) {
      this.getCartItemCount()
    }
    this.getStoreDetailsAtCatalogue()
    this.getProductDetails();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  handleVariantData = () => {
    const variantId = this.getNavParams().variantId;
    const variantData = variantId ? this.state.productData?.attributes.catalogue_variants?.find(variant => variant.id === variantId) : undefined;
    this.setState({ variantData: variantData })
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };

  getProductDetails = () => {
    const header = { "Content-Type": configJSON.productApiContentType };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productApiItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPointSlug}${this.getNavParams().id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddItemToCartButton = async () => {
    if (this.state.addedToBag) {
      window.location.href = `${window.location.origin}/shopping-cart`
    } else {
      mixpanel.track(configJSON.addToCartEvent, { email: this.state.email, buildCardId: this.state.build_card });
      const cartIdExist = await getStorageData('cartId');
      this.setState({ loader: true })
      if (!cartIdExist) {
        this.createCartApi();
      } else {
        this.addItemToCartApi()
      }
    }
  }

  getCartItemCount = async () => {
    let cartId = await localStorage.getItem('cartId');

    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCartApiEndPoint}/${cartId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  addItemToCartApi = async () => {
    const header = { "Content-Type": configJSON.productApiContentType };
    const cartID = await getStorageData('cartId')
    const body = {
      cart_items: [
        {
          product_id: this.state.productData.id,
          cart_id: cartID,
          quantity: 1,
          catalogue_variant_id: this.getNavParams().variantId
        }
      ]
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addItemToCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addItemToCartApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createCartApi = () => {
    const header = { "Content-Type": configJSON.productApiContentType };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCartApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTargetVariant = (variantOption: VariantOptions1, value: string) => {
    const productData = this.state.productData;
    const selectedOptionsList = this.state.variantOptions.map(option => ({
      parentValue: option.id,
      value: option.id === variantOption.id ? value : option.optionsList.find(property => property.isSelected)?.value ?? ""
    }));

    return this.findVariantByOptions(productData.attributes.catalogue_variants, selectedOptionsList)
  }

  handleOptionChange = (variantOption: VariantOptions1, newValue: string) => {
    const targetVariant = this.getTargetVariant(variantOption, newValue);
    if (targetVariant) {
      variantOption.optionsList.forEach(option => {
        option.isSelected = newValue === option.value
      });
      this.setState({ variantOptions: [...this.state.variantOptions], addedToBag: false }, () => this.setState({ AddToBagText: "Add to bag" }));
      const slug = this.getSlug(this.state.productData);
      this.navigateToVariant(slug, targetVariant.id);
    }
  }

  handleOptionClick = (variantOption: VariantOptions1) => {
    const variants = this.state.productData.attributes.catalogue_variants;
    const otherSelectedOptions = this.state.variantOptions.filter(option => option.id !== variantOption.id)
      .map(option => ({
        parentValue: option.id,
        value: option.optionsList.find(property => property.isSelected)?.value ?? ""
      }));
    variantOption.optionsList.forEach(option => {
      const variantToCheck = [option, ...otherSelectedOptions];
      const variant = this.findVariantByOptions(variants, variantToCheck);
      option.disabled = !variant;
    });

    this.setState({ variantOptions: [...this.state.variantOptions] });
  }

  navigateToVariant = (productId: string | number, variantId?: string | number) => {
    this.props.navigation.navigate("ProductDescription", variantId ? {
      idOrVariantId: productId,
      variantId
    } : { idOrVariantId: productId });
    setTimeout(() => {
      this.handleVariantData()
    }, 0);
  }

  setSelectedOptions = (variants: CatalogueVariant1[], requestedVariantId: string, variantOptionsList: VariantOptions1[]) => {
    // set selected items of the dropdowns by the requested variant
    const requestedVariant = variants.find(variant => variant.id === requestedVariantId);
    if (requestedVariant) {
      const variantProperties: VariantProperty1[] = requestedVariant.attributes.catalogue_variant_properties?.data;
      variantProperties.forEach(property => {
        const selectedOption = variantOptionsList.find(variantOption => variantOption.id === property.attributes.variant_option.name);
        if (selectedOption) {
          selectedOption.optionsList.forEach(item => {
            item.isSelected = item.value === property.attributes.name;
          });
        }
      });
    }
  }

  setVariantProperties = (variantProperties: VariantProperty1[], variantOptionsList: VariantOptions1[]) => {
    variantProperties.forEach(property => {
      const dropdownOption = {
        label: property.attributes.name,
        value: property.attributes.name,
        parentValue: property.attributes.variant_option.name,
      };
      const option = variantOptionsList.find(option => option.id === property.attributes.variant_option.name);
      if (option) {
        const optionProperty = option.optionsList.find(property => property.value === dropdownOption.value);
        if (!optionProperty) {
          option.optionsList.push(dropdownOption);
        }
      }
      else {
        variantOptionsList.push({
          id: property.attributes.variant_option.name,
          name: property.attributes.variant_option.name,
          optionsList: [dropdownOption]
        });
      }
    });
  }

  generateVariantOptions = (productData?: ProductData1) => {
    // generate unique options for variant dropdowns
    const variantOptions: VariantOptions1[] = [];
    const variants = productData?.attributes.catalogue_variants;
    if (Array.isArray(variants)) {
      const requestedVariantId = this.getNavParams().variantId;
      variants.forEach(variant => {
        const variantProperties = variant.attributes.catalogue_variant_properties?.data;
        if (Array.isArray(variantProperties)) {
          this.setVariantProperties(variantProperties, variantOptions);
        }
      })
      this.setSelectedOptions(variants, requestedVariantId, variantOptions);
    }
    return variantOptions;
  }

  variantOptionsSorter = (opt1: OptionsList1, opt2: OptionsList1) => opt1.parentValue.localeCompare(opt2.parentValue);

  findVariantByOptions = (variants: CatalogueVariant1[], selectedOptionsList: OptionsList1[]) => {
    const requestingVariant = selectedOptionsList.map(option => ({
      parentValue: option.parentValue,
      value: option.value
    }));
    requestingVariant.sort(this.variantOptionsSorter);

    return variants.find(variant => {
      let productVariants: OptionsList1[] = variant.attributes.catalogue_variant_properties.data.map(variantProp => ({
        parentValue: variantProp.attributes.variant_option.name,
        value: variantProp.attributes.name
      }));
      productVariants = productVariants.filter((property, index: number, self) => self.findIndex(data => data.parentValue === property.parentValue) === index);
      productVariants.sort(this.variantOptionsSorter);
      return isEqual(requestingVariant, productVariants);
    });
  }

  handleBreadCrumbClick = (category: string, subCategory: string) => {
    localStorage.setItem('category', category);
    localStorage.setItem('subCategory', subCategory);
  }

  getSlug = (productData: ProductData1) => {
    const productSlug = productData.attributes.slug;
    const categorySlug = productData.attributes.category.slug;
    const subCategorySlug = productData.attributes.selected_sub_category?.slug;
    let slug = `${categorySlug}/${productSlug}`;
    if (subCategorySlug) {
      slug = `${categorySlug}/${subCategorySlug}/${productSlug}`;
    }
    return slug;
  }

  getNavParams = (): { categorySlug: string, subCategorySlug: string, id: string, variantId: string } => {
    let { categorySlug, subCategorySlugOrId, idOrVariantId, variantId } = {
      categorySlug: this.props.navigation.getParam("categorySlug"),
      subCategorySlugOrId: this.props.navigation.getParam("subCategorySlugOrId"),
      idOrVariantId: this.props.navigation.getParam("idOrVariantId"),
      variantId: this.props.navigation.getParam("variantId")
    };

    let subCategorySlug, id;

    if (!variantId && !isNaN(parseInt(idOrVariantId))) {
      id = subCategorySlugOrId;
      variantId = idOrVariantId;
    } else if (idOrVariantId) {
      id = idOrVariantId;
      subCategorySlug = subCategorySlugOrId;
    } else {
      id = subCategorySlugOrId;
    }

    return { categorySlug, subCategorySlug, id, variantId };
  }

  getYoutubeVideoId = (url: string) => (
    url?.split("youtu.be/")[1]?.split("?")[0] || url?.split("youtu.be/")[1] 
      || url?.split("v=")[1]?.split("&")[0] || url?.split("v=")[1]
  )

  getYoutubeThumbnail = () => (
    configJSON.youtubeImgUrl.replace("{video_id}", this.getYoutubeVideoId(this.state.productData.attributes.video_url))
  )

  getYoutubeEmbeddedUrl = (videoUrl: string) => {
    let urlValue = videoUrl.includes("youtube.com") ? videoUrl.replace("watch?v=", "embed/").split("&")[0] : videoUrl;
    urlValue = videoUrl.includes("youtu.be") ? videoUrl.replace("youtu.be", "youtube.com/embed") : urlValue;
    return urlValue;
  }

  handleVideoModal = () => {
    this.setState({ videoModalOpen: !this.state.videoModalOpen })
  }

  handleRender = () => {
    const { productData, variantData } = this.state;
    let images, addToBagDisabled: boolean, realPrice, salePrice, onSale, discountRate;
    let videoThumbnail = productData?.attributes?.video_url && this.getYoutubeThumbnail();
    if (variantData) {
      images = variantData.attributes.images ?? [];
      addToBagDisabled = variantData.attributes.stock_qty <= 0;
      realPrice = variantData.attributes.price;
      salePrice = variantData.attributes.sale_price;
      onSale = Number(variantData.attributes.discount) > 0;
      discountRate = variantData.attributes.discount;
    } else {
      images = productData?.attributes.images ?? [];
      addToBagDisabled = productData?.attributes.stock_qty <= 0;
      realPrice = productData?.attributes.price;
      salePrice = productData?.attributes.sale_price;
      onSale = productData?.attributes.on_sale;
      discountRate = productData?.attributes.discount;
    }
    if (videoThumbnail && !images?.find(image => image.url === videoThumbnail)) {
      images?.push({ url: videoThumbnail });
    }
    return { images, addToBagDisabled, realPrice, salePrice, onSale, discountRate, videoThumbnail };
  }
  // Customizable Area End
}
